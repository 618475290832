<script>
  import * as Alfa from '../modules/alfa.module'
  import * as Dabrabyt from '../modules/dabrabyt.module'
  import * as Prior from '../modules/prior.module'
  import * as API from '../modules/api.module'
  import BigNumber from '../modules/bignumber.module'
  import Input from './controls/input.svelte'
  import Button from './controls/button.svelte'
  import Select from './controls/select.svelte'
  import Navigation from './navigation.svelte'
  import { user, rate, accounts, bank, tooltip } from '../modules/store.module'
  import { saveSelectedTransactions } from '../modules/transactions.module'

  export let quarters
  export let transactions
  export let customProperties

  let loading = false
  let actualPayment
  $: selectedAccount = $accounts?.find(({active}) => active)?.number || ' '

  const isInYear = (selectedYear) => ({ year }) => year === selectedYear
  const isSelected = ({ selected }) => selected
  const subtract = (a, b) => BigNumber(a).minus(BigNumber(b))
  const sumProperty = (property) => (sum, item) => sum.plus(BigNumber(item[property] || 0))
  const extractProperty = (property) => (item) => item[property]
  const setActiveAccountByNumber = (accountNumber) => (account) => ({ ...account, active: accountNumber === account.number })
  const handleAccountSelection = ({ target: { value: accountNumber }}) => $accounts = $accounts.map(setActiveAccountByNumber(accountNumber))
  const toggleProperty = (propertyName) => {
    if (!customProperties) customProperties = []
    if (!customProperties.includes(propertyName)) return customProperties = [...customProperties, propertyName]
    customProperties = customProperties.filter(customProperty => customProperty !== propertyName)
  }

  $: taxPercentage = $rate === 'УСН' ? 6 : 20
  $: selectedQuarter = quarters.find(isSelected)
  $: selectedYear = selectedQuarter.year
  $: quartersInYear = quarters.filter(isInYear(selectedYear))
  $: selectedTotalIndex = quartersInYear.findIndex(isSelected)
  $: allQuarters = quartersInYear.slice(0, selectedTotalIndex + 1)
  
  $: yearRevenue = allQuarters.reduce(sumProperty('revenue'), BigNumber(0)).toFormat(2)
  $: yearExpenses = $rate === 'УСН' ? '0,00' : allQuarters.reduce(sumProperty('expenses'), BigNumber(0)).toFormat(2)
  $: yearProfit = subtract(yearRevenue, yearExpenses).toFormat(2)
  $: yearStandardDeduction = $rate === 'УСН' ? '0,00' : allQuarters.reduce(sumProperty('standardDeduction'), BigNumber(0)).toFormat(2)
  $: yearIncomeDeduction = $rate === 'УСН' ? '0,00' : allQuarters.reduce(sumProperty('incomeDeduction'), BigNumber(0)).toFormat(2)
  $: yearInferiorDeduction = $rate === 'УСН' ? '0,00' : allQuarters.reduce(sumProperty('inferiorDeduction'), BigNumber(0)).toFormat(2)
  $: yearSpecialStatusDeduction = $rate === 'УСН' ? '0,00' : allQuarters.reduce(sumProperty('specialStatusDeduction'), BigNumber(0)).toFormat(2)
  $: yearSocialDeduction = $rate === 'УСН' ? '0,00' : allQuarters.reduce(sumProperty('socialDeduction'), BigNumber(0)).toFormat(2)
  $: yearPropertyDeduction = $rate === 'УСН' ? '0,00' : allQuarters.reduce(sumProperty('propertyDeduction'), BigNumber(0)).toFormat(2)
  $: yearTotalDeduction = $rate === 'УСН' ? '0,00' : allQuarters.reduce(sumProperty('totalDeduction'), BigNumber(0)).toFormat(2)
  $: yearExemption = $rate === 'УСН' ? '0,00' : BigNumber(yearTotalDeduction).plus(BigNumber(yearExpenses)).toFormat(2)
  $: yearTaxBase = subtract(yearRevenue, yearExemption).toZeroIfNegative().toFormat(2)
  $: yearTax = BigNumber(yearTaxBase).div(100).times(taxPercentage).toFormat(2)
  $: yearIsEmployed = allQuarters.flatMap(extractProperty('isEmployed'))
  $: yearInferiors = allQuarters.flatMap(extractProperty('inferiors'))
  $: yearTotalWageFund = allQuarters.reduce(sumProperty('totalWageFund'), BigNumber(0)).toFormat(2)
  $: yearTotalWageRate = allQuarters.reduce(sumProperty('totalWageRate'), BigNumber(0)).toFormat(2)
  $: yearAverageWageRate = BigNumber(yearTotalWageRate).div(selectedQuarter.number).div(3).toFormat(2)

  $: previousQuarters = quartersInYear.slice(0, selectedTotalIndex)
  $: previousRevenue = previousQuarters.reduce(sumProperty('revenue'), BigNumber(0)).toFormat(2)
  $: previousExpenses = $rate === 'УСН' ? '0,00' : previousQuarters.reduce(sumProperty('expenses'), BigNumber(0)).toFormat(2)
  $: previousProfit = subtract(previousRevenue, previousExpenses).toZeroIfNegative().toFormat(2)
  $: previousTotalDeduction = $rate === 'УСН' ? '0,00' : previousQuarters.reduce(sumProperty('totalDeduction'), BigNumber(0)).toFormat(2)
  $: previousTaxBase = subtract(previousProfit, previousTotalDeduction).toZeroIfNegative().toFormat(2)
  $: previousTax = BigNumber(previousTaxBase).div(100).times(taxPercentage).toFormat(2)
  $: expectedPayment = BigNumber(yearTax).minus(BigNumber(previousTax)).toFormat(2)
  
  const calculateActualPayment = () => BigNumber(expectedPayment).gte(0) ? expectedPayment : '0,00'

  $: if (!customProperties?.includes('actualPayment') && expectedPayment) { 
    setTimeout(() => actualPayment = calculateActualPayment(), 0)
  }

  $: declaration = {
    user: {
      ...$user,
      bank: $bank
    },
    rate: $rate,
    quarter: selectedQuarter,
    year: {
      revenue: yearRevenue,
      ...($rate !== 'УСН') && { expenses: yearExpenses },
      ...($rate !== 'УСН') && { profit: yearProfit },
      ...($rate !== 'УСН') && { standardDeduction: yearStandardDeduction },
      ...($rate !== 'УСН') && { incomeDeduction: yearIncomeDeduction },
      ...($rate !== 'УСН') && { inferiorDeduction: yearInferiorDeduction },
      ...($rate !== 'УСН') && { specialStatusDeduction: yearSpecialStatusDeduction },
      ...($rate !== 'УСН') && { socialDeduction: yearSocialDeduction },
      ...($rate !== 'УСН') && { propertyDeduction: yearPropertyDeduction },
      ...($rate !== 'УСН') && { totalDeduction: yearTotalDeduction },
      ...($rate !== 'УСН') && { exemption: yearExemption },
      taxBase: yearTaxBase,
      tax: yearTax,
      isEmployed: yearIsEmployed,
      inferiors: yearInferiors,
      totalWageFund: yearTotalWageFund,
      averageWageRate: yearAverageWageRate
    },
    previous: {
      tax: previousTax,
    },
    payment: {
      expected: expectedPayment,
      actual: actualPayment,
    }
  }

  const submitDeclaration = async () => {
    try {
      await saveSelectedTransactions($user.number, transactions) 
      await saveUserInput($user.number, quarters)
      const declarationID = await saveDeclaration(declaration)
      if (!declaration.payment?.requested) {
        window.location = `/declarations/${declarationID}`
        loading = false
        return
      } 
      if ($bank === 'alfa') {
        await Alfa.payTax(declaration, declarationID)
        loading = false
        return
      } 
      if ($bank === 'dabrabyt') {
        await Dabrabyt.payTax(declaration, declarationID)
        loading = false
        return
      }
      if ($bank === 'prior') {
        await Prior.payTax(declaration, declarationID)
        loading = false
        return
      }
    }
    catch (e) {
      console.log(e)
      loading = false
      $tooltip = { heading: 'Ошибка', contents: 'Произошла ошибка. Пожалуйста, обратитесь за помощью в поддержку сервиса.' }
    }
  }
  
  const refocusAllFields = () => document.querySelectorAll('input').forEach(input => (input.focus() || input.blur()))
  const getParent = (item) => item.parentNode
  const shakeInput = (input) => {
    const errorMessage = input.querySelector('.input__error').innerText
    const inputField = input.querySelector('.input__field')
    inputField.setCustomValidity('')
    setTimeout(() => inputField.setCustomValidity(errorMessage), 0)
  }

  const scrollIntoView = (firstErrorInput) => {
    const top = firstErrorInput.getBoundingClientRect().top + window.scrollY -16
    window.scrollTo({ top, behavior: 'instant' })
  }

  const handleClick = async (paymentIsRequested) => {
    if (declaration.payment.actual === '0,00' && paymentIsRequested) return $tooltip = { heading: 'Ошибка', contents: 'Нельзя оплатить нулевую сумму. Скачайте декларацию без оплаты или отредактируйте сумму к оплате.'}
    declaration.payment.requested = paymentIsRequested
    loading = true
    refocusAllFields()
    const errorMessages = [...document.querySelectorAll('.input__error')].filter(({ innerText }) => innerText)
    if (!errorMessages.length) return submitDeclaration()
    loading = false
    const errorInputs = errorMessages.map(getParent)
    const firstErrorInput = errorInputs[0]
    scrollIntoView(firstErrorInput)
    errorInputs.forEach(shakeInput)
  }
  const saveDeclaration = async (declaration) => {
    const { declarationID } = await API.saveDeclaration(declaration)
    return declarationID
  }

  const shouldEntryBeSaved = (properties) => ([ key ]) => properties.includes(key)

  const extractDataToBeSaved = (quarter) => {
    const defaultProperties = ['unaccountedRevenue', 'unaccountedExpenses', 'children', 'dependents', 'isEmployed', 'isSpecialParent', 'isSpecialStatus', 'socialDeduction', 'propertyDeduction', 'wageRate']
    const customProperties = quarter.customProperties || []
    const propertiesToBeSaved = [...defaultProperties, ...customProperties]
    const quarterEntries = Object.entries(quarter)
    const entriesToSave = quarterEntries.filter(shouldEntryBeSaved(propertiesToBeSaved))
    const { year, number } = quarter
    const properties = Object.fromEntries(entriesToSave)
    const dataToBeSaved = { year, number, properties }
    return dataToBeSaved
  }

  const saveUserInput = async (userNumber, quarters) => {
    const dataToBeSaved = quarters.map(extractDataToBeSaved)
    return API.saveQuarters(userNumber, dataToBeSaved)
  }

</script>

<section class="total">
  <div class="total__summary">
    {#if $rate !== 'УСН'}
      <Input class="total__item" value={declaration.year.revenue} enabled={false} type={'float'} postfix={'BYN'}>
        <span slot="label">Доходы за год</span>
        <svelte:fragment slot="tooltip">
          <p>Сумма доходов нарастающим итогом с начала года, включая отчетный квартал.</p>
        </svelte:fragment>
      </Input>
      <Input class="total__item" value={declaration.year.expenses} enabled={false} type={'float'} postfix={'BYN'}>
        <span slot="label">Расходы за год</span>
        <svelte:fragment slot="tooltip">
          <p>Сумма расходов нарастающим итогом с начала года, включая отчетный квартал.</p>
        </svelte:fragment>
      </Input>
      <Input class="total__item" value={declaration.year.profit} enabled={false} type={'float'} postfix={'BYN'}>
        <span slot="label">Прибыль за год</span>
        <svelte:fragment slot="tooltip">
          <p>Сумма прибыли (доходы минус расходы) нарастающим итогом с начала года, включая отчетный квартал.</p>
        </svelte:fragment>
      </Input>
      <Input class="total__item" value={declaration.year.totalDeduction} enabled={false} type={'float'} postfix={'BYN'}>
        <span slot="label">Вычеты за год</span>
        <svelte:fragment slot="tooltip">
          <p>Сумма вычетов нарастающим итогом с начала года, включая отчетный квартал.</p>
        </svelte:fragment>
      </Input>
    {/if}
    <Input class="total__item" value={declaration.year.taxBase} enabled={false} type={'float'} postfix={'BYN'}>
      <span slot="label">Налоговая база за год</span>
      <svelte:fragment slot="tooltip">
        <p>Сумма прибыли{#if $rate !== 'УСН'}, уменьшенная на вычеты,{/if} нарастающим итогом с начала года включая отчетный квартал, подлежащая налогообложению.</p>
      </svelte:fragment>
    </Input>
    <Input class="total__item" value={declaration.year.tax} enabled={false} type={'float'} postfix={'BYN'}>
      <span slot="label">Налоги за год</span>
      <svelte:fragment slot="tooltip">
          <p>Сумма налогов нарастающим итогом с начала года включая отчетный квартал.</p>
        </svelte:fragment>
    </Input>
    <Input class="total__item" value={declaration.previous.tax} enabled={false} type={'float'} postfix={'BYN'}>
      <span slot="label">Уплачено за год</span>
      <svelte:fragment slot="tooltip">
          <p>Сумма уже уплаченных налогов нарастающим итогом с начала года без отчетного квартала.</p>
        </svelte:fragment>
    </Input>
    <Input class="total__item" value={declaration.payment.expected} enabled={false} type={'float'} postfix={'BYN'}>
      <span slot="label">Налог к оплате{#if $rate !== 'УСН'}/возврату{/if}</span>
      <svelte:fragment slot="tooltip">
        <p>Итоговая сумма налога, подлежащая уплате в отчетном квартале{#if $rate !== 'УСН'}, или возврату по итогам года{/if}.</p>
      </svelte:fragment>
    </Input>
  </div>
  <div class="total__checkout">
    {#if declaration.payment.expected !== '0,00' && ['alfa', 'dabrabyt', 'prior'].includes($bank)}
      <Input class="total__item total__item--start_3" bind:value={actualPayment} {customProperties} {toggleProperty} property={'actualPayment'} type={'float'} postfix={'BYN'}>
        <span slot="label">Оплатить в ИМНС</span>
        <svelte:fragment slot="tooltip">
          <p>Сумма налога, которую вы фактически собираетесь оплатить в ИМНС.</p>
          <p>Редактировать сумму уплачиваемого налога необходимо лишь в том случае, когда у вас есть переплата налога за предыдущий квартал и вы зафиксировали эту переплату уточняющей декларацией за тот же квартал. Тогда вы можете уменьшить сумму уплачиваемого налога в этом квартале на сумму переплаты. Точную сумму, на которую вы можете уменьшить налог вам лучше узнать (сверить) у своего налогового инспектора. Уплачиваемые налоги строго контролируются ИМНС, без необходимости не редактируйте данное поле.</p>
        </svelte:fragment>
      </Input>
      <Select class="total__item total__item--type_account" onChange={handleAccountSelection} value={selectedAccount}>
        <svelte:fragment slot="label">Счет для оплаты налога</svelte:fragment>
        <svelte:fragment slot="options">
          {#each $accounts as account}
            {#if account.currency.ISO === 'BYN'}
              <option value={account.number}>
                {account.number.substr(0, 8)} — {account.amount} {account.currency.ISO}
              </option>
            {/if}
          {/each}
        </svelte:fragment>
      </Select>
    {/if}
    <div class="total__buttons">
      {#if declaration.payment.expected !== '0,00' && ['alfa', 'dabrabyt', 'prior'].includes($bank)}
        <Button on:click={() => handleClick(true)} {loading}>Оплатить и скачать</Button>
      {/if}
      <Button type={'secondary'} on:click={() => handleClick(false)} {loading}>Скачать без оплаты</Button>
    </div>
    {#if declaration.quarter.number === quarters[quarters.length - 1].number && declaration.quarter.year === quarters[quarters.length - 1].year}
      <div class="total__notification">Квартал еще не завершен. Скачивание декларации за незавершенный квартал необходимо только для закрытия ИП/юрлица</div>
    {/if}
  </div>
</section>

<Navigation/>

<style lang="scss">
  .total {
    padding: 16px 0;
    &__summary {
      display: grid;
      align-items: center;
      justify-content: flex-end;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, max-content);
      column-gap: 16px;
    }
    & :global(.total__item) {
      grid-column: span 3;
      @media (max-width: 1279px) {
        grid-column: span 4;
      }
      @media (max-width: 768px) {
        grid-column: span 6;
      }
      @media (max-width: 459px) {
        grid-column: span 12;
      }
    }
    & :global(.total__item--start_3) {
      grid-column: 3 / span 3;
      @media (max-width: 1279px) {
        grid-column: 1 / span 4;
      }
      @media (max-width: 768px) {
        grid-column: 1 / span 6;
      }
      @media (max-width: 459px) {
        grid-column: span 12;
      }
    }
    & :global(.total__item--type_account) {
      grid-column: 6 / span 3;
      @media (max-width: 1279px) {
        grid-column: 5 / span 4;
      }
      @media (max-width: 768px) {
        grid-column: 7 / span 6;
      }
      @media (max-width: 459px) {
        grid-column: span 12;
      }
    }
    &__checkout {
      display: grid;
      align-items: center;
      justify-content: flex-end;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, max-content);
      column-gap: 16px;
    }
    &__buttons {
      display: flex;
      grid-column: 9 / span 4;
      column-gap: 8px;
      order: 2;
      @media (max-width: 768px) {
        grid-column: 1 / span 12;
        order: 3;
      }
      @media (max-width: 459px) {
        flex-direction: column;
        row-gap: 8px;
      }
    }
    &__notification {
      background-color: rgb(252, 165, 165);
      color: #EF3124;
      padding: 16px;
      display: flex;
      column-gap: 16px;
      font-size: 14px;
      line-height: 18px;
      grid-column: 9 / span 4;
      margin-bottom: 8px;
      order: 3;
      &:before {
        content: '❗';
      }
      @media (max-width: 1023px) {
        grid-column: 5 / span 8;
        order: 2;
      }
      @media (max-width: 768px) {
        grid-column: 1 / span 12;
      }
    }
  }
</style>